import { insurances } from "patient_app/helpers/insuranceHelpers";
import SessionStore from "patient_app/helpers/SessionStore";
import permissions from "patient_app/helpers/permissions";
import _ from "lodash";

// import { DateTime } from 'luxon';

export let getProgramType = () => {
  let program = "coach";
  if (window.location.href.includes("/opiates/")) {
    program = "oud";
  } else if (window.location.href.includes("/alcohol/")) {
    program = "aud";
  } else if (window.location.href.includes("/therapy/in_")) {
    program = "counselor";
  }

  return program;
};

export let getLocalPhone = (existingUser) => {
  let phone = "855-659-7734";
  let user = existingUser ? existingUser : SessionStore.get("user");
  if (!user || !user.state) {
    return phone;
  }

  switch (user.state) {
    case "MI":
      phone = "734-329-5419";
      break;
    case "CA":
      phone = "925-395-4255";
      break;
    case "NJ":
      phone = "609-474-0106";
      break;
    case "AK":
      phone = "907-268-2327";
      break;
    case "WA":
      phone = "734-329-5419";
      break;
    case "FL":
      phone = "954-231-3080";
      break;
    case "MN":
      phone = "651-349-4342";
      break;
    case "OH":
      phone = "216-438-0283";
      break;
    case "OR":
      phone = "503-461-7106";
      break;
    case "TX":
      phone = "737-245-7587";
      break;
    default:
      return phone;
      break;
  }

  return phone;
};

//"unknown", "man", "woman", "transgender_man", "transgender_woman", "nonbinary", "option_not_listed", "prefer_not_to_say", "two_spirit"
let onboardingHelpers = {
  optionsForSexAtBirth() {
    return [
      ["Female", "Female"],
      ["Male", "Male"],
      ["Intersex/Other", "Other"],
    ];
  },
  optionsForGenderIdentity() {
    return [
      ["Woman", "woman"],
      ["Man", "man"],
      ["Transgender woman / trans feminine", "transgender_woman"],
      ["Transgender man / trans masculine", "transgender_man"],
      ["Nonbinary / genderqueer / gender fluid", "nonbinary"],
      ["Two spirit", "two_spirit"],
      ["Option not listed", "option_not_listed"],
      ["Prefer not to say", "prefer_not_to_say"],
    ];
  },
  optionsForGender() {
    // LEGACY ONLY - use either optionsForSexAtBirth or optionsForGenderIdentity
    return [
      ["Female", "FEMALE"],
      ["Male", "MALE"],
      ["I prefer not to say", "PREFER_NOT_TO_SAY"],
      ["Other", "OTHER"],
    ];
  },
  optionsForEmployer() {
    return [
      ["honigman", "Honigman"],
      ["Kirkland Ellis", "Kirkland & Ellis"],
      ["salesforce", "Salesforce"],
      ["steelcase", "Steelcase"],
      ["Logicalis", "Logicalis"],
      ["demo_purposes", "demo_purposes"],
      ["Red Bull", "Red Bull"],
    ];
  },
  optionsForInsurance(state = null) {
    const insuranceList = insurances();
    let patientInsurances = [];

    for (let insurance of insuranceList) {
      let formatted = [
        insurance.title,
        insurance.value ? insurance.value : insurance.title,
      ];
      if (!insurance.state || state === "all") {
        patientInsurances.push(formatted);
      }

      if (
        insurance.state &&
        state &&
        state === insurance.state &&
        state !== "all"
      ) {
        patientInsurances.push(formatted);
      }
    }

    return patientInsurances;
  },
  optionsForInsuranceType() {
    return [
      ["Commercial", "Commercial"],
      ["Medicaid/Medi-Cal", "Medicaid/Medi-Cal"],
      ["Dual-eligible", "Dual-eligible"],
      ["Medicare", "Medicare"],
    ];
  },
  optionsForReferral() {
    return [
      ["Social Media / Advertisement", "advertisement"],
      ["My Doctor, Therapist, or Clinic", "doctor"],
      ["CVS Health Hub", "cvs_health_hub"],
      ["My Friend or Family Member", "friend_family"],
      ["My Employer", "employer"],
      ["Phone or Online Directory", "online_directory"],
      ["Emergency Services/911", "emergency_services"],
      ["App Store", "app_store"],
      ["Online search", "google"],
      ["Oak Street Health", "oak_street_health"],
      ["HCSC Mental Health Hub", "hcsc"],
      ["Other", "other"],
      ["Prefer not to say", "prefer_not_to_say"],
    ];
  },
  vices() {
    return [
      {
        name: "DRINKING",
        label: 2,
        title: "Drinking",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/drinking.png",
      },
      {
        name: "OPIOIDS",
        label: 11,
        title: "Opioids",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/pills.png",
      },
      {
        name: "DRUGS",
        label: 3,
        title: "Other Drugs",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/drugs.svg",
      },
      {
        name: "SMOKING",
        label: 4,
        title: "Smoking",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/smoking.png",
      },
      {
        name: "GAMBLING",
        label: 5,
        title: "Gambling",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/gambling.svg",
      },
      {
        name: "SEX&LOVE",
        label: 6,
        title: "Sex & Porn",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/sex%26love.png",
      },
      {
        name: "EATING",
        label: 8,
        title: "Eating",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/eating.png",
      },
      {
        name: "PAIN",
        label: 13,
        title: "Pain",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/vices/pain.png",
      },
    ];
  },
  recommenders() {
    return [
      {
        name: "PARENT",
        title: "Parent",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/parent.png",
      },
      {
        name: "CHILD",
        title: "Child",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/child.png",
      },
      {
        name: "COWORKER",
        title: "Co Worker",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/coworker.png",
      },
      {
        name: "PARTNER",
        title: "Partner",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/partner.png",
      },
      {
        name: "FRIEND",
        title: "Friend",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/friend.png",
      },
      {
        name: "OTHER",
        title: "Other",
        image:
          "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/other.png",
      },
    ];
  },
  getSelfPayProgramCost(user, primaryDiagnosis) {
    if (!user || (user && !user.tier)) {
      return {
        programName: "Coach",
        recurringCost: "$25",
        interval: "week",
      };
    }

    if ([1, 2].includes(user.tier) && user?.token?.length > 0) {
      return {
        programName: "Enterprise",
        recurringCost: "$0",
        interval: "",
      };
    }

    switch (user?.tier) {
      case 1:
        return {
          programName: "Coach",
          recurringCost: "$25",
          interval: "week",
        };
      case 2:
        return {
          programName: "Counselor",
          recurringCost: "$50",
          interval: "week",
        };
      case 3:
        if (permissions.isAud(user, primaryDiagnosis)) {
          return {
            programName: "Clinic",
            firstApptCost: "$100",
            recurringCost: "$150",
            interval: "month",
          };
        } else {
          return {
            programName: "Clinic",
            firstApptCost: "$15",
            recurringCost: "$75",
            interval: "week",
          };
        }
      case 4:
        return {
          programName: "Enterprise",
          recurringCost: "$0",
          interval: "",
        };
      default:
        return {
          programName: "",
          recurringCost: "",
          interval: "",
        };
    }
  },
  getProgramDescription(programType, clinic = null) {
    // programType comes from getProgramType()
    const hasCounseling = permissions.isInClinicStateWithCounseling(clinic);
    let descriptions = [
      "Virtual appointments with a licensed clinician",
      "Management of your prescribed medication",
      "Optional 1:1 coaching sessions for an additional fee",
      "Unlimited chat-based messaging with your care team",
    ];
    if (programType === "coach") {
      descriptions = [
        "Recovery program tailored to your specific needs",
        "Unlimited chat-based messaging with your dedicated coach",
        "Personalized recovery curriculum",
        "This program does not include medication",
        "Non-judgemental support every step of the way",
        "Access to Workit’s private Facebook group",
        "Access to Workit’s virtual workshops, activities and events",
      ];
    } else if (programType === "counselor") {
      descriptions = [
        "Recovery program tailored to your specific needs",
        `Unlimited chat-based messaging with your ${
          hasCounseling ? "licensed counselor" : "dedicated coach"
        }`,
        hasCounseling
          ? "1:1 weekly therapy sessions via video, chat or phone"
          : "",
        "This program does not include medication",
        "Personalized recovery curriculum",
        "Non-judgemental support every step of the way",
        "Access to Workit’s private Facebook group",
        "Access to Workit’s virtual workshops, activities and events",
      ];

      if (!hasCounseling) {
        // match with coach description
        descriptions = [
          "Recovery program tailored to your specific needs",
          "Unlimited chat-based messaging with your dedicated coach",
          "Personalized recovery curriculum",
          "This program does not include medication",
          "Non-judgemental support every step of the way",
          "Access to Workit’s private Facebook group",
          "Access to Workit’s virtual workshops, activities and events",
        ];
      }
      // OUD is not using onboardingHelpers for description in app/javascript/patient_app/views/onboarding/ProgramDescription.jsx
    } else if (programType === "oud") {
      // PA: take out counselor and support group
      descriptions = [
        "Intake appointment with your licensed provider",
        "Medication for opioid use disorder and coexisting condtions (as appropriate)",
        hasCounseling
          ? "Shared medical follow-up visits with your provider, a coach, and peers"
          : "",
        "At-home drug screens mailed to you (at no additional cost)",
        hasCounseling
          ? "Unlimited online recovery groups led by peers and counselors"
          : "",
        "Interactive content library to help you retrain your brain",

        // "Virtual appointments with a licensed clinician",
        // "Management of Suboxone or naltrexone",
        // hasCounseling
        //   ? "Online support groups led by a licensed counselor"
        //   : "",
        // hasCounseling ? "Optional 1:1 coaching sessions" : "",
        // "Unlimited chat-based messaging with your care team",
        // "At-home drug testing, at no additional cost",
        // "Hand-picked therapeutic recovery curriculum",
        // "Access to Workit’s private Facebook group",
        // "Access to Workit’s virtual workshops, activities and events",
      ];
      // AUD is not using onboardingHelpers for description in app/javascript/patient_app/views/onboarding/ProgramDescription.jsx
    } else if (programType.includes("aud")) {
      // PA: take out counselor and support group
      descriptions = [
        "Intake appointment with your licensed provider",
        "Medication to manage cravings and coexisting conditions",
        hasCounseling
          ? "Shared medical follow-up visits with your provider, a coach, and peers"
          : "",
        hasCounseling
          ? "Unlimited online recovery groups led by peers and counselors"
          : "",
        "Interactive content library to help you retrain your brain",
        // "Virtual appointments with a licensed clinician",
        // "Management of naltrexone or acamprosate",
        // "Unlimited chat-based messaging with your care team",
        // "Hand-picked therapeutic recovery curriculum",
        // "Access to Workit’s private Facebook group",
        // "Access to Workit’s virtual workshops, activities and events",
      ];
    } else if (programType.includes("dep")) {
      descriptions = [
        "Virtual appointments with a licensed clinician",
        "Medication management when clinically appropriate",
        "Unlimited chat-based messaging with your care team",
        "Hand-picked therapeutic recovery curriculum",
        "Access to Workit’s virtual workshops, activities and events",
      ];
    } else if (programType.includes("anx")) {
      descriptions = [
        "Virtual appointments with a licensed clinician",
        "Medication management when clinically appropriate",
        "Unlimited chat-based messaging with your care team",
        "Hand-picked therapeutic recovery curriculum",
        "Access to Workit’s virtual workshops, activities and events",
      ];
    }

    return descriptions.filter((desc) => !!desc);
  },
  // key is used for questionnaire
  calculateProgress(url, key = null) {
    let offset = 0;
    if (url.includes("questionnaire")) {
      if (url.includes("/start")) {
        offset = 0;
      } else {
        let lastChar = key.charCodeAt(key.length - 1);
        if (lastChar >= 97 && lastChar <= 122) {
          // 8 is the max number of questionnaire questions
          // 0.4 is the step size between questionnaire start and outcome
          offset = ((lastChar - 97) / 8) * 0.4;
        }
      }
      console.log;
      url = "/enterprise_onboarding/questionnaire";
    } else if (url.includes("outcome")) {
      url = "/enterprise_onboarding/outcome";
    }

    const steps = {
      "/onboarding/insurance_check": 0,
      "/onboarding/insurance_check_results": 0,
      "/onboarding/safety_check": 1,
      "/onboarding/safety_alert": 1,
      "/onboarding/zip_code": 2,
      "/onboarding/treatment_type": 3,
      "/onboarding/alternate": 3,
      "/onboarding/signup": 4,
      "/onboarding/verify_phone": 5,
      "/onboarding/in_person": 6,
      "/onboarding/program_description": 7,
      "/onboarding/treatment_type?reselect=true": 7,
      "/onboarding/check": 8,
      "/onboarding/check_control": 8,
      "/onboarding/check_explanation": 8,
      "/onboarding/program_cost": 9,
      "/onboarding/state_insurers": 10,
      "/onboarding/insurance_check_auth": 11,
      "/onboarding/insurance_check_auth_results": 11,
      "/onboarding/insurance_call_us": 11,
      "/onboarding/employer_code": 11,
      "/onboarding/employer_verification": 11,
      "/onboarding/review_cost": 12,
      "/payments/purchase": 13,
      "/onboarding/signup_name": 14,
      "/enterprise_onboarding/occupation": 15,
      "/onboarding/primary_curriculum": 15,
      "/onboarding/secondary_curriculum": 15,
      "/onboarding/submit_id": 16,
      "/onboarding/consent": 17,
      "/onboarding/address": 18,
      "/onboarding/verify_address": 18,
      "/onboarding/schedule": 19,
      "/onboarding/schedule_call": 19,
      "/onboarding/conclusion": 20,
    };

    let step = steps[url];
    if (!step) {
      step = 0;
    }
    return (step / 17) * 100;
  },
  statesWithFee(program) {
    let states = ["MI", "AK", "OH", "TX"];
    let cost = "$25.00";
    let interval = "weekly";
    if (program === "aud") {
      states = ["WA", "MI", "OH", "TX"];
      cost = "$40.00";
      interval = "monthly";
    }

    return [states, cost, interval];
  },

  /*
    @params
    [clinicPrograms (array)]
    [includeECC (boolean)]
  */
  optionsForProgram(clinicPrograms, includeECC) {
    let uniqueTreatments = {};
    clinicPrograms.forEach((cp) => {
      // similar programs uids should be formatted as program_subprogram,
      // e.g. aud_moderation and aud_abstinence are both "aud", so get unique
      // programs by setting key-value pairs
      const uid = cp.uid.split("_")[0];
      if (uniqueTreatments[uid]) {
        uniqueTreatments[uid].uid.push(cp.uid);
      } else {
        uniqueTreatments[uid] = { ...cp, uid: [cp.uid] };
      }
    });

    let programOptions = [];
    // sort programs alphabetically
    const uniqueTreatmentsSorted = _.sortBy(
      Object.values(uniqueTreatments),
      "patient_facing_name"
    );

    // format programs for select dropdown
    uniqueTreatmentsSorted.forEach((cp) => {
      programOptions.push([cp.patient_facing_name, cp.uid.sort().join(",")]);
    });

    if (includeECC) {
      // if Online Therapy is an option, include it at the end of the list
      programOptions.push(["Online Therapy", "ecc"]);
    }

    return programOptions;
  },
};

export default onboardingHelpers;
